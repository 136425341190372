/* SidebarExplore.css */

/* Hide sidebar on mobile by default */
/* .sidebar1 {
  display: none;
} */

.d-block {
  display: block !important;
}

/* .d-none {
  display: none !important;
} */

/* Mobile-specific styles */
@media (max-width: 767px) {
  .mobile-menu-btn {
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }

  .sidebar1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #fff;
    overflow-y: auto;
  }

  .btn-close {
    z-index: 1001;
  }
}
